/*--------------[Variables]--------------*/
//fonts
$headingFont: 'Fredericka the Great', cursive;
$primaryFont: 'Raleway', sans-serif;
$secondFont: 'Montserrat', sans-serif;


//colors
$primaryColor: #1e6a74;
$secondColor: #174b53;
$thirdColor: #d97136;
$forthColor: #ffa86c;
$card-bg: #f1f1f1;
$background : #fefefe;
$black: #000000;
$blackTransparent: rgba(0,0,0,0.85);
$white: #ffffff;
$dropShadow-black: rgba(0,0,0,0.6);

//Margin's & Padding's
$marginSide: 50px;
$marginSections: 30px;
$spacingPrimary: 15px;
$spacingSecondary: 10px;

//grid
$mobile: 600px;
$tablet: 1050px;
$desktop: 2560px;

//footer
$footerH: 50px;

/*--------------|end of Variables|--------------*/