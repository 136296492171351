.page {
  &__contact {
    padding: 50px 0;
    position: relative;
  }
}
.contact {
  &__form {
    @extend .form;
    max-width: 1100px;
    margin: 0 auto;
  }

  //start of prompt
  &__prompt {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 60%;
    left: 50%;
    max-width: 500px;
  }
  &-prompt {
    background-color: $card-bg;
    height: 185px;
    &__topBar {
      background-color: #1e6a74;
      text-align: right;
      padding: 5px;
      border-radius: 5px 5px 0 0;
    }
    &__close {
      font-weight: bold;
      padding: 5px 7px;
      border: none;
      color: white;
      border-radius: 3px;
      &:hover {
        background-color: rgba(0,0,0,0.3);
      }
    }
    &__content {
      font-size: 20px;
      padding: 30px 15px;
      border-radius: 0 0 5px 5px;
    }
    &__ok {
      display: block;
      width: 75px;
      margin: 0 15px 15px auto;
    }
  }
  //end of prompt
}